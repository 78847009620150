import {Toast, ToastContainer} from "react-bootstrap";
import * as React from "react";

type PropsTypes = {
  state: any;
  text: {
    title: string;
    body: string;
  }
}

const LoginToast: React.FC<PropsTypes> = ({state, text}) => {
  const {show, setShow} = state;

  return (
    <ToastContainer position="bottom-end">
      <Toast bg="danger" onClose={() => setShow(false)} show={show} autohide>
        <Toast.Header>
          <strong className="me-auto">{text.title}</strong>
        </Toast.Header>
        <Toast.Body style={{color: "white"}}>{text.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default LoginToast;
