import * as React from "react";
import { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { TokenModel, TokenModelSchema } from "../../models/token";
import LoginToast from "../../components/common/loginToast";
import PageHelmet from "../../components/common/helmet";

const LoginPage: React.FC = () => {
  const [token, setToken] = useState("");
  const [show, setShow] = useState(false);
  const [toastText, setToastText] = useState<{ title: string; body: string }>({
    title: "",
    body: "",
  });

  const loginHandler = () => {
    try {
      const tokenData: TokenModel = JSON.parse(atob(token));
      TokenModelSchema.validate(tokenData);

      localStorage.setItem("TOKEN", JSON.stringify(tokenData));
      window.location.reload();
    } catch (err) {
      setShow(true);
      setToastText({
        title: "Error",
        body: "Token tidak valid",
      });
      setToken("");
    }
  };

  // useEffect(() => {
  //   try {
  //     useAuth();
  //     navigate("/app/loan");
  //   } catch (e) {}
  // }, []);

  return (
    <>
      <PageHelmet title="Login" />
      <Container className="mt-3">
        <Row>
          <Col md={4} />
          <Col md={4}>
            <Card>
              <Card.Header as="h5">Login</Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="password"
                      placeholder="Token"
                      value={token}
                      onChange={(e) => {
                        setToken(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <div className="d-grid gap-2">
                    <Button variant="primary" onClick={() => loginHandler()}>
                      Submit
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} />
        </Row>
        <LoginToast state={{ show: show, setShow: setShow }} text={toastText} />
      </Container>
    </>
  );
};
export default LoginPage;
